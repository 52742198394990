import React from "react"
import styled from "styled-components"
import BottomSpacing from "../components/BottomSpacing"
import HalfFlex from "../components/HalfFlex"
import Default from "../layouts/default"
import MobileHidden from "../components/MobileHidden"

const PageInfo = {
    desc: "Pension in zentraler Lage direkt am Markt mit modern eingerichteten Zimmern und Ferienwohnungen für 1 – 4 Personen",
    keywords: "kontakt, pension, erzgebirge, zimmer, zschopau, hotel, ferienwohnung, zur altdeutschen, einzelzimmer, doppelzimmer",
    title: 'Kontakt – Pension "Zur Altdeutschen"'
}

const KontaktTable = styled.table`
    min-width: 290px;
    tr td:first-of-type {
        width: 70%;
    }
`

const Kontakt = () => <Default pageInfo={PageInfo} headerImg="/assets/img/AdobeStock_152404369.jpeg" isActive="kontakt">
    <HalfFlex>
        <div>
            <BottomSpacing>
                <h1>Kontakt</h1>
                <p>
                    Zögern Sie nicht, uns zu kontaktieren. Wir freuen uns, von Ihnen zu hören.
                </p>
                <address>
                    <b>Pension "Zur Altdeutschen"</b><br />
                    Neumarkt 1<br />
                    09405 Zschopau<br /><br />
                    <a href="tel:+49372522734">+49 3725 227 34</a><br />
                    <a href="tel:+4917612626043">+49 176 1262 60 43 (mobil)</a><br />
                    <a href="mailto:zuraltdeutschen@aol.com">zuraltdeutschen@aol.com</a>
                </address>
            </BottomSpacing>
            <h2>Telefonische Erreichbarkeit</h2>
            <KontaktTable>
                <tr>
                    <td>Mo. – Fr.</td>
                    <td>9:00 –</td>
                    <td>18:30</td>

                </tr>
                <tr>
                    <td>Sa.</td>
                    <td>9:00 –</td>
                    <td>15:30</td>

                </tr>
                <tr>
                    <td>So. und Feiertage</td>
                    <td>11:00 –</td>
                    <td>15:30</td>

                </tr>
            </KontaktTable>
        </div>
        <div>
            <MobileHidden><img src="/assets/img/pension-quer.jpg" alt="Pension"/></MobileHidden>
        </div>
    </HalfFlex>
</Default>

export default Kontakt